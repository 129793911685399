<template>
	<div class="users pa-3">
		<SocketWatch :tables="['users']" @loadData="loadData" />
		<v-card>
			<div class="d-flex align-center px-4">
				<v-row class="page_filterTable mt-3">
					<v-col cols="3">
						<v-text-field dense v-model="searchUsers" color="rgb(224, 166, 119)" label="Имя/E-mail"
							placeholder="Иван/m4@m4r.ru" />
					</v-col>
					<v-col cols="3">
						<v-autocomplete dense color="rgb(224, 166, 119)" v-model="filterUserByRole" :items="userRoles"
							label="Роль сотрудника" clearable />
					</v-col>
				</v-row>
				<v-spacer></v-spacer>
				<v-btn icon small @click="createUser()" class="pr-3">
					<v-icon>mdi-account-plus-outline</v-icon>
				</v-btn>
			</div>
			<v-divider />
			<v-simple-table :dense="!isMobile">
				<template v-slot:default>
					<thead>
						<tr>
							<th class="text-left">Аватар</th>
							<th class="text-left">Имя</th>
							<th class="text-left">E-mail</th>
							<th class="text-left">Район</th>
							<th class="text-left">Группа</th>
							<th class="text-left">Роль</th>
							<th class="text-left">Норма час</th>
							<th class="text-right">Действия</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="user in users" :key="user.id" style="cursor: pointer;"
							@click="user.id != '7FwbL1TfxtVX9TlEsJZfJyuXvg13' ? goUser(user) : null">
							<td>
								<my-mini-avatar :user="user" :key="user.avatar" />
							</td>
							<td>{{ user.displayName }}</td>
							<td>{{ user.email }}</td>
							<td>{{ user.location }}</td>
							<td>
								<span v-if="user.brig">{{ user.brig.displayName }}</span>
								<span v-else>Не указана</span>
							</td>
							<td style="width: 260px;">{{ roleText[user.role] }}</td>
							<td>
								<span v-if="(user.role === 'master' && user.rate === 0) || user.rate">{{ user.rate }}р.</span>
								<span v-else>Не указан</span>
							</td>
							<td class="text-right">
								<v-btn v-if="user.uid != '7FwbL1TfxtVX9TlEsJZfJyuXvg13'" @click.stop="editUser(user)" icon small
									color="#424242" class="mr-1">
									<v-icon size="18">mdi-pencil</v-icon>
								</v-btn>
								<v-btn v-if="user.uid != '7FwbL1TfxtVX9TlEsJZfJyuXvg13'" @click.stop="deleteUser(user)" icon small
									color="#bf4037">
									<v-icon size="18">mdi-trash-can-outline</v-icon>
								</v-btn>
							</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</v-card>
	</div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import UserModal from '@/components/Modals/User/Index'
import Confirm from '@/components/Modals/Confirm'
import { RoleText } from '@/helpers'

export default {
	name: 'Users',
	components: {},
	data: () => ({
		isMobile: isMobile,
		roleText: RoleText,
		error: null
	}),
	async created() {
		// if (this.users.length === 0) {
		//   this.$store.dispatch('users/loadUsers')
		// }
		// Надо вызывать при каждом заходе что бы обновилась инфа о бригаде

		this.loadData()
	},
	methods: {
		loadData() {
			this.$store.dispatch('users/loadUsers')
		},
		goUser(user) {
			this.$router.push({ name: 'User', params: { uid: user.id } })
		},
		async editUser(user) {
			await this.$showModal({
				component: UserModal,
				isPersistent: true,
				props: {
					edit: true,
					data: user
				}
			})
			this.loadData()
		},
		async createUser() {
			await this.$showModal({
				component: UserModal,
				isPersistent: true
			})
			this.loadData()
		},
		async deleteUser(user) {
			if (user.role === 'manager') {
				const res = await this.$showModal({
					component: Confirm,
					isPersistent: true,
					props: {
						onChangeManager: true,
						title: `Выберите нового менеджера`,
						text: `Выберите, кем вы хотите замените "${user.displayName}", на роль менеджера?`,
                        delUserId: user.id
					}
				})
				if (res) {
					await this.$store.dispatch('users/deleteUser', user)
					this.$store.dispatch('users/loadUsers')
				}
			} else {
				const res = await this.$showModal({
					component: Confirm,
					isPersistent: true,
					props: {
						title: `Удаление пользователя`,
						text: `Действительно хотите удалить пользователя "${user.displayName}"?`
					}
				})
				if (res) {
					await this.$store.dispatch('users/deleteUser', user)
					this.$store.dispatch('users/loadUsers')
				}
			}

		}
	},
	computed: {
		filterUserByRole: {
			get() { return this.$store.getters['users/getFilterUserByRole'] },
			set(val) { this.$store.commit('users/setFilterUserByRole', val) }
		},
		searchUsers: {
			get() { return this.$store.getters['users/getSearchUsers'] },
			set(val) { this.$store.commit('users/setSearchUsers', val) }
		},
		users() {
			return this.$store.getters['users/getUsers']
		},
		userRoles() {
			const arrUserRoles = []
			for (let key in this.roleText) {
				arrUserRoles.push({ value: key, text: this.roleText[key] })
			}
			return arrUserRoles
		}
	}
}
</script>
